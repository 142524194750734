import { graphql } from 'gatsby';
import React from 'react';
import '../builderComponents/CategorySlider/CategorySlider';
import '../builderComponents/PLPBanner/PLPBanner';
import '../builderComponents/PLPReview/PLPReview';
import '../builderComponents/SeoContent/SeoContent';
import Catalog from '../components/organisms/Catalog/Catalog';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';

export const query = graphql`
  query CategoryTemplateQuery($id: String!, $path: String!) {
    builder: allBuilderModels {
      onePlpBanner(target: { urlPath: $path }, options: { cachebust: true }) {
        content
      }
      onePlpBottomSection(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
      onePlpBottomSeoContent(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
    }
    category: allBigCommerceCategories(filter: { id: { eq: $id } }) {
      edges {
        node {
          bigcommerce_id
          description
          id
          image_url
          meta_description
          page_title
          name
        }
      }
    }
  }
`;

const CategoryTemplate = props => {
  const { data, errors, pageContext } = props;
  const category = data && data.category.edges[0].node;
  const headerContent = data && data.builder.onePlpBanner?.content;
  const footerContent = data && data.builder.onePlpBottomSection?.content;
  const seoContent = data && data.builder.onePlpBottomSeoContent?.content;
  return (
    <Layout>
      {errors && <Seo title="GraphQL Error" />}
      {category && (
        <Seo
          title={category.page_title || category.name}
          description={category.meta_description || ''}
        />
      )}

      {/* {errors && (
            <Container>
              <GraphQLErrorList errors={errors} />
            </Container>
        )} */}

      {category && (
        <Catalog
          pageContext={pageContext}
          category={category}
          headerContent={headerContent}
          footerContent={footerContent}
          seoContent={seoContent?.data}
        />
      )}
    </Layout>
  );
};

export default CategoryTemplate;
